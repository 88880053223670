import { Fragment, useEffect, useState } from 'react';

import crc16modbus from 'crc/crc16modbus';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import PinIcon from '@mui/icons-material/Pin';
import StraightenIcon from '@mui/icons-material/Straighten';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';

import Title from './Title';

export default function Settings(props) {
    const { btBusy, btExecute, data, setData, interval, properties, sendProperties, setRawData } = props

    const [ sliderState, setSliderState ] = useState({
        speedLimit: data?.speedLimit || 25,
        supportLevel: data?.supportLevel || 0
    })

    useEffect(() => {
        if (data.speedLimit !== undefined && data.supportLevel !== undefined ) {
            console.log("data changed!!!")
            if (sliderState.speedLimit !== data.speedLimit && sliderState.supportLevel !== data.supportLevel) {
                setSliderState({
                    speedLimit: data.speedLimit,
                    supportLevel: data.supportLevel
                })
            } else if (sliderState.supportLevel !== data.supportLevel) {
                setSliderState((prevState) => {
                    return {
                        ...prevState,
                        supportLevel: data.supportLevel
                    }
                })
            } else if (sliderState.speedLimit !== data.speedLimit) {
                setSliderState((prevState) => {
                    return {
                        ...prevState,
                        speedLimit: data.speedLimit
                    }
                })
            }
        }
    }, [data])

    const changeSetting = (property, value) => {
        const arr = [ 87 ]
        arr.push(2 + property.bytes)
        arr.push(property.number)
        arr.push(property.bytes)
    
        if (property.bytes === value.length) {
            const mergedArr = [
                ...arr,
                ...value
            ]
    
            const checksum = crc16modbus(mergedArr).toString(16)
            const checksumComplete = checksum.length === 2 ? "00" + checksum : checksum
            console.log("my merged arr is this: ", mergedArr)
            console.log("my checksumComplete is this: ", checksumComplete)
            mergedArr.push("0x" + checksumComplete[2] + checksumComplete[3])
            mergedArr.push("0x" + checksumComplete[0] + checksumComplete[1])
            mergedArr.push(69)
          
            const writeValue = new Uint8Array(mergedArr)

            btExecute(writeValue)
        } else {
            console.log("number of bytes and value array length do not match – changes not approved")
        }
      }

    const handleSlider = (propertyName, value) => {
        interval.current && clearInterval(interval.current)
        if (propertyName === "speedLimit") {
            setSliderState((prevState) => {
                return {
                    ...prevState,
                    speedLimit: value
                }
            })
        } else {
            setSliderState((prevState) => {
                return {
                    ...prevState,
                    supportLevel: value
                }
            })
        }
    }

    const handleToggle = ( propertyName, value ) => {
        const toggle = () => {
            if (propertyName === ("light")) {
                changeSetting(properties.find(property => property.name === propertyName), data[propertyName] ? [0] : [1])
                setData((prevState) => {
                    return {
                        ...prevState,
                        [propertyName]: ! prevState[propertyName]
                    }
                })
            } else if (propertyName === ("unit")) {
                if (value !== null) {
                    changeSetting(properties.find(property => property.name === propertyName), [value])
                    setData((prevState) => {
                        return {
                            ...prevState,
                            [propertyName]: value
                        }
                    })   
                }
            } else {
                setData((prevState) => {
                    return {
                        ...prevState,
                        [propertyName]: value
                    }
                })    
                if (propertyName === "speedLimit") {
                    changeSetting(properties.find(property => property.name === propertyName), [value, 0])   
                } else {
                    changeSetting(properties.find(property => property.name === propertyName), [value])   
                }
            }
            interval.current = setInterval(() => {
                setRawData([])
                sendProperties()
            }, 1000)
        }

        interval.current && clearInterval(interval.current)

        if (btBusy) {
            setTimeout(toggle, 500)
        } else {
            toggle()
        }
    }

    return (
        <Fragment>
            <Title>Settings</Title>
            <List
                sx={{ 
                    width: '100%', 
                    display: {
                        lg: "flex",
                    },
                    gap: {
                        lg: "3.5em"
                    } 
                }}
            >
                <ListItem>
                    <ListItemIcon>
                        <LightbulbIcon />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-front-light" primary="Front Light" />
                    <Switch
                        disabled={data.light === undefined}
                        edge="end"
                        onChange={(event) => handleToggle("light")}
                        checked={Boolean(data?.light) || false}
                        value="light"
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-front-light',
                        }}
                    />
                </ListItem>
                <ListItem sx={{flexWrap: "wrap"}}>
                    <ListItemIcon>
                        <PinIcon />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-support-level" primary="Support Level (PAS)" />
                    <Slider
                        disabled={data.supportLevel === undefined}
                        value={sliderState.supportLevel}
                        onChange={(event, value) => handleSlider("supportLevel", value)}
                        onChangeCommitted={(event, value) => handleToggle("supportLevel", value)}
                        getAriaValueText={value => value}
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={0}
                        max={3}
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-support-level',
                        }}
                        sx={{flexBasis: "100%"}}
                    />
                </ListItem>
                <ListItem sx={{flexWrap: "wrap"}}>
                    <ListItemIcon>
                        <OfflineBoltIcon />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-speed-limit" primary="Speed Limit" />
                    <Slider
                        disabled={data.speedLimit === undefined}
                        value={sliderState.speedLimit}
                        onChange={(event, value) => handleSlider("speedLimit", value)}
                        onChangeCommitted={(event, value) => handleToggle("speedLimit", value)}
                        getAriaValueText={value => value}
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={20}
                        max={37}
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-speed-limit',
                        }}
                        sx={{flexBasis: "100%"}}
                    />
                </ListItem>
                <ListItem sx={{flexWrap: "wrap"}}>
                    <ListItemIcon>
                        <StraightenIcon />
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-unit" primary="Unit" />
                    <ToggleButtonGroup
                        onChange={(event, value) => handleToggle("unit", value)}
                        value={data.unit}
                        color="primary"
                        exclusive
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-unit',
                        }}
                    >
                        <ToggleButton disabled={data.unit === undefined} value={0}>km</ToggleButton>
                        <ToggleButton disabled={data.unit === undefined} value={1}>mi</ToggleButton>
                    </ToggleButtonGroup>
                </ListItem>
            </List>
        </Fragment>
    );
}