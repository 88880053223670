import { Fragment, useState } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';

import BluetoothIcon from '@mui/icons-material/Bluetooth';
import PedalBikeIcon from '@mui/icons-material/PedalBike';

const Connect = props => {
    const { device, setDevice, setRawData, setService } = props

    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [snackbar, setSnackbar] = useState({
      open: false,
      severity: "success",
      message: ""
    })
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleSnackbarClose = () => {
      setSnackbar(prevState => {
        return {
          ...prevState,
          open: false
        }
      })
    };

    const handleConnect = () => {
      
      if (! navigator.bluetooth) {
        setSnackbar({
          open: true,
          severity: "error",
          message: "Your browser does not support the Web Bluetooth API (yet). Current status: https://caniuse.com/web-bluetooth"
        })
        return
      }

      navigator.bluetooth.requestDevice({
        filters: [
          {
            name: "CGO600 Pro",
          },
        ],
        // UART service
        optionalServices: ["6e400001-b5a3-f393-e0a9-e50e24dcca9e"],
      })
      .then(device => {
        setDevice(device)
        setSnackbar({
          open: true, 
          severity: "success", 
          message: `Connected to ${device.name}`
        })
        console.log('Connecting to GATT Server...');
        return device.gatt.connect();
      })
      .then(server => {
        console.log('Getting Service...');
        return server.getPrimaryService("6e400001-b5a3-f393-e0a9-e50e24dcca9e");
      })
      .then(service => {
        setService(service)
        console.log('Getting Characteristic...');
        return service.getCharacteristic("6e400003-b5a3-f393-e0a9-e50e24dcca9e");
      })
      .then(characteristic => {
        return characteristic.startNotifications().then(_ => {
          console.log('> Notifications started');
          setSnackbar({
            open: true, 
            severity: "success", 
            message: "Notifications started"
          })          
          characteristic.addEventListener('characteristicvaluechanged',
              handleNotifications
          );
        });
      })
      .catch(error => {
        setSnackbar({
          open: true,
          severity: "error",
          message: "Could not connect to bike – see logs for details"
        })
        console.log(error.message);
      }); 
    }

    function handleNotifications(event) {
      let value = event.target.value;

      let rawDataPart = []
      // Convert raw data bytes to hex values just for the sake of showing something.
      // In the "real" world, you'd use data.getUint8, data.getUint16 or even
      // TextDecoder to process raw data bytes.
      console.log("myValue: ", value)

      for (let i = 0; i < value.byteLength; i++) {
        rawDataPart.push(value.getUint8(i))
      }

      setRawData((prevState) => [
        ...prevState,
        ...rawDataPart
      ]) 
    }

    return (
      <Fragment>
        <Tooltip title="Connect to your bike" arrow open={! Boolean(device) && ! Boolean(anchorEl)}>
          <IconButton color="inherit" onClick={handleClick}>
            <Badge color="secondary" badgeContent={device ? 1 : 0}>
              <BluetoothIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <ListItem disabled={! device} >
            <ListItemIcon style={{minWidth: 35}}>
              <PedalBikeIcon />
            </ListItemIcon>
            <ListItemText>{ device ? device.name : "not connected"}</ListItemText>
          </ListItem>
          <Divider />
          <MenuItem onClick={handleConnect}>Connect</MenuItem>
        </Menu>
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Fragment>        
    )
}

export default Connect