import { useEffect, useRef, useState } from 'react';
import crc16modbus from 'crc/crc16modbus';

import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import SpeedIcon from '@mui/icons-material/Speed';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Connect from './components/Connect';
import CurrentSpeed from './components/CurrentSpeed';
import BigNumber from './components/BigNumber';
import Settings from './components/Settings';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://jasperw.de/">
        Jasper Witkowski
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const properties = [
  {
    name: "currentSpeed",
    number: 17,
    bytes: 2
  },
  {
    name: "maxSpeed",
    number: 18,
    bytes: 2
  },
  {
    name: "avgSpeed",
    number: 19,
    bytes: 2
  },
  {
    name: "trip",
    number: 20,
    bytes: 4
  },
  {
    name: "tripTime",
    number: 21,
    bytes: 2
  },
  {
    name: "range",
    number: 22,
    bytes: 4
  },
  {
    name: "mileage",
    number: 23,
    bytes: 4
  },
  {
    name: "battery",
    number: 26,
    bytes: 1
  },
  {
    name: "light",
    number: 33,
    bytes: 1
  },
  {
    name: "supportLevel",
    number: 34,
    bytes: 1
  },
  {
    name: "speedLimit",
    number: 37,
    bytes: 2
  },
  {
    name: "unit",
    number: 42,
    bytes: 1
  }
]


export default function App() {
  const [device, setDevice] = useState("")
  const [service, setService] = useState("")
  const [pollingStarted, setPollingStarted] = useState(false)
  const [rawData, setRawData] = useState([])
  const [data, setData] = useState([])
  const [btBusy, setBtBusy] = useState(false)

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const defaultTheme = createTheme({
    breakpoints: {
      values: {
        xxs: 0,
        xs: 430,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      secondary: {
        main: '#5a668d',
        light: '#949dc2',
        dark: '#252d47'
      },
      mode: prefersDarkMode ? "dark" : "light"
    }
  });

  const interval = useRef()

  const multiplicator = data?.unit ? 0.6214 : 1

  const sendProperties = () => {
  
    const arr = [ 82 ]
    arr.push(properties.length)
    for (const property of properties) {
      arr.push(property.number)
    }
  
    const checksum = crc16modbus(arr).toString(16)
    arr.push("0x" + checksum[2] + checksum[3])
    arr.push("0x" + checksum[0] + checksum[1])
    arr.push(69)
    
    const writeValue = new Uint8Array(arr)

    btExecute(writeValue)
  
  }

  
  const btExecute = (action) => {
    service?.getCharacteristic("6e400002-b5a3-f393-e0a9-e50e24dcca9e")
    .then((characteristic) => {
      return characteristic
    })
    .then((characteristic) => {
      characteristic.writeValue(action)
      console.log(action, " written ")
    })
    .then(() => {
      setBtBusy(false)
    })
    .catch((error) => {
      console.log("error sending properties: ", error.message);
    }); 
  }

  useEffect(() => {
    if (service && ! pollingStarted) {
      console.log(`successfully connected, my service is ${service}`)
      interval.current = setInterval(() => {
        setRawData([])
        setBtBusy(true)
        sendProperties()
      }, 1000)
      setPollingStarted(true)
    }
  }, [service])

  useEffect(() => {
    let sum = 5
    sum += properties.length * 2
    for (const property of properties) {
      sum += property.bytes
    }
    if (rawData[0] === 82 && rawData.length === sum) {
      let slicedRawData = rawData.slice(2, rawData.length - 3)
      let position = 0
      const dataObj = {}
      for (const property of properties) {
        if (property.number === slicedRawData[position]) {
          if (property.bytes > 1 && property.name !== "speedLimit") {
            dataObj[property.name] = parseInt(slicedRawData.slice(position + 2, position + 2 + property.bytes).map(int => int.toString(16)).join(""), 16)
          } else if (property.name === "speedLimit") {
            dataObj[property.name] = slicedRawData.slice(position + 2, position + 2 + property.bytes)[1]
          } else {
            dataObj[property.name] = slicedRawData.slice(position + 2, position + 2 + property.bytes)[0]
          }
          position = position + property.bytes + 2
        }
      }
      setData(dataObj)
    } else {
      console.log("raw data not set to data, its value is: ", rawData)
    }
  }, [rawData])

  console.log("this is my data: ", data)

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Backdrop
          sx={{ 
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1 
          }}
          open={! Boolean(device)}
        ></Backdrop>
        <AppBar 
          sx={{
            backgroundColor: defaultTheme.palette.secondary.dark
          }} 
          position="absolute"
        >
          <Container maxWidth="lg" sx={{px: "16px !important"}}>
            <Toolbar
              sx={{
                px: "24px !important"
              }}
            >
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                Bike Monitor
              </Typography>
              <Connect device={device} setDevice={setDevice} setRawData={setRawData} setService={setService} />
            </Toolbar>
          </Container>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : '#141414',
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Current Speed */}
              <Grid item xxs={12} md={5}>
                <Paper
                  sx={{
                    p: {
                      xxs: 2,
                      lg: 3
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: "100%",
                    minHeight: 300,
                  }}
                >
                  <CurrentSpeed currentSpeed={Math.floor(data?.currentSpeed * multiplicator)} unit={data?.unit ? "mi/h" : "km/h"} />
                </Paper>
              </Grid>
              <Grid item container xxs={12} md={7} spacing={3} flexDirection="row" alignItems="top">
                {/* Max. Speed */}
                <Grid item xxs={12} xs={6}>
                  <Paper
                    sx={{
                      p: {
                        xxs: 2,
                        md: 3
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      height: "100%",
                    }}
                  >
                    <BigNumber icon={<SpeedIcon color="primary" fontSize="medium" />} property="Max. Speed" value={data.maxSpeed !== undefined ? Math.floor(data.maxSpeed * multiplicator) / 10 : "-"} unit={data?.unit ? "mi/h" : "km/h"} />
                  </Paper>
                </Grid>
                {/* Avg. Speed */}
                <Grid item xxs={12} xs={6}>
                  <Paper
                    sx={{
                      p: {
                        xxs: 2,
                        md: 3
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      height: "100%",
                    }}
                  >
                    <BigNumber icon={<Typography color="primary" component="span" sx={{mt: "-5px", fontWeight: 200}} variant="h4">⌀</Typography>} property="Avg. Speed" value={data.avgSpeed !== undefined ? Math.floor(data.avgSpeed * multiplicator) / 10 : "-"} unit={data?.unit ? "mi/h" : "km/h"} />
                  </Paper>
                </Grid>
                {/* Trip */}
                <Grid item xxs={12} xs={6}>
                  <Paper
                    sx={{
                      p: {
                        xxs: 2,
                        md: 3
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      height: "100%",
                    }}
                  >
                    <BigNumber icon={<DirectionsBikeIcon color="primary" fontSize="medium" />} property="Trip" value={data.trip !== undefined ? Math.floor(data.trip * multiplicator) / 10 : "-"} unit={data?.unit ? "mi" : "km"} />
                  </Paper>
                </Grid>
                {/* Trip time */}
                <Grid item xxs={12} xs={6}>
                  <Paper
                    sx={{
                      p: {
                        xxs: 2,
                        md: 3
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      height: "100%",
                    }}
                  >
                    <BigNumber icon={<AccessTimeIcon color="primary" fontSize="medium" />} property="Trip time" value={data.tripTime !== undefined ? Math.floor(data.tripTime / 60) : "-"} unit="min" />
                  </Paper>
                </Grid>
                {/* Battery status */}
                <Grid item xxs={12} xs={6} lg={4}>
                  <Paper
                    sx={{
                      p: {
                        xxs: 2,
                        md: 3
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      height: "100%",
                    }}
                  >
                    <BigNumber icon={<Battery5BarIcon color="primary" fontSize="medium" />} property="Battery" value={data.battery !== undefined ? data.battery : "-"} unit="%" />
                  </Paper>
                </Grid>
                {/* Range */}
                <Grid item xxs={12} xs={6} lg={4}>
                  <Paper
                    sx={{
                      p: {
                        xxs: 2,
                        md: 3
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      height: "100%",
                    }}
                  >
                    <BigNumber icon={<ElectricBikeIcon color="primary" fontSize="medium" />} property="Range" value={data.range !== undefined && data.range !== 16776960 ? Math.floor(data.range / 10 * multiplicator, 1) : "-"} unit={data?.unit ? "mi" : "km"} />
                  </Paper>
                </Grid>
                {/* Total Mileage */}
                <Grid item xxs={12} lg={4}>
                  <Paper
                    sx={{
                      p: {
                        xxs: 2,
                        md: 3
                      },
                      display: 'flex',
                      flexDirection: 'column',
                      height: "100%",
                    }}
                  >
                    <BigNumber icon={<EmojiEventsIcon color="primary" fontSize="medium" />} property="Mileage" value={data.mileage !== undefined ? Math.floor(data?.mileage / 10 * multiplicator) : "-"} unit={data?.unit ? "mi" : "km"} />
                  </Paper>
                </Grid>
              </Grid>
              {/* Settings */}
              <Grid item xxs={12}>
                <Paper 
                  sx={{ 
                    p: {
                      xxs: 2,
                      md: 3
                    },
                    display: 'flex', 
                    flexDirection: 'column' 
                  }}>
                  <Settings btBusy={btBusy} btExecute={btExecute} data={data} setData={setData} interval={interval} properties={properties} sendProperties={sendProperties} setRawData={setRawData} />
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}