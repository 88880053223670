import { Fragment } from 'react';
import GaugeComponent from 'react-gauge-component'
import { useTheme } from '@mui/material';

import Title from './Title';

const CurrentSpeed = props => {
    const theme = useTheme()

    const { currentSpeed, unit } = props

    const gaugeOptions = {
        maxValue: {
            "km/h": 400,
            "mi/h": 250
        },
        ticks: {
            "km/h": [
                { value: 0 },
                { value: 100 },
                { value: 200 },
                { value: 300 },
                { value: 400 }
            ],
            "mi/h": [
                { value: 0 },
                { value: 50 },
                { value: 100 },
                { value: 150 },
                { value: 200 },
                { value: 250 }
            ]
        }
    }

    return (
        <Fragment>
            <Title>Current Speed in {unit}</Title>
            <GaugeComponent
                id="gauge-component-radial"
                value={currentSpeed || 0}
                maxValue={gaugeOptions.maxValue[unit]}
                type="radial"
                arc={{
                    limit: 1,
                    width: 0.2,
                    nbSubArcs: 20,
                    colorArray: [theme.palette.secondary.light, theme.palette.secondary.dark]
                }}
                labels={{
                    valueLabel: {
                        formatTextValue: (value) => value / 10,
                        style: { 
                            fill: theme.palette.text.secondary, 
                            textShadow: "none",
                            fontWeight: 500
                        }
                    },
                    tickLabels: {
                        type: "inner",
                        defaultTickValueConfig: {
                            formatTextValue: (value) => value / 10,
                            style: { fontSize: "1em" }
                        },
                        ticks: gaugeOptions.ticks[unit]
                    }
                }}
                pointer={{ 
                    color: theme.palette.primary.main,
                    length: 0.5 
                }}
                marginInPercent={{
                    top: 0.03,
                    bottom: 0.00, 
                    left: 0.05,
                    right: 0.05
                }}
                style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: 420
                }}
            />
        </Fragment>
    )
}

export default CurrentSpeed