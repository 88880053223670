import { Fragment } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Title from './Title';

export default function BigNumber(props) {
  const {icon, property, value, unit} = props

  return (
    <Fragment>
      <Title>{property}</Title>
      <Stack direction="row" gap={1.5} alignItems="center">
        {icon && icon}
        <Typography component="p" variant="h4" fontWeight="500" display="inline-block">
          {value} {unit && <Typography component="span" color="text.secondary">{unit}</Typography>}
        </Typography>
      </Stack>
    </Fragment>
  );
}
